table input{
    text-align: center;
  }
  .deleteColumn{
    border: solid 2px rgb(209, 79, 79);
    background-color: rgb(209, 79, 79);
    color: white;
    cursor: pointer;
  }
  .editColumn{
    border: solid 2px #0bbe20;
    background-color: #0bbe20;
    color: white;
    cursor: pointer;
  }
  
  .checkedItems{
    background-color: grey;
  }
  
  #saveButton {
    padding: 2px 11px 6px;
    background-color: #0191ff;
    margin-left: 2px;
    color: white;
    font-weight: bold;
  }
  #updateButton {
    padding: 2px 11px 6px;
    background-color: #0bbe20;
    margin-left: 2px;
    color: white;
    font-weight: bold;
  }
  #loader {
    position: absolute;
    background-color: rgba(128,128,128, .5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
  #loader p{
    text-align: center;
    width: 100%;
  }

  span.mycheckbox {
    cursor: pointer;
    border: solid 1px grey;
    position: relative;
    box-shadow: 3px 3px 5px 2px grey;
    background: white;
    display: block;
    width: 30px;
    height: 30px;
}
span.mycheckbox::after {
  content:"";
}
div:has(.form-check-input:checked) + span.mycheckbox {
  background: #0e19f97a;
}

div:has(.form-check-input:checked) + span.mycheckbox::after{
 content:"✔️";
 font-size: 20px;

}
.form-check-input{
  display: none;
}