.deleteButton
{
  background-color: rgb(196, 10, 10);
  color: white;
  padding: 0px 3px;
  cursor: pointer;
}

.saveButton{
  background-color: rgb(10, 91, 242);
  color: white;
  padding: 0px 3px;
}

#addTransactionButton {
  position: fixed;
  right: 10px;
  bottom: 10px;
  color: white;
  padding: 0px 3px;
  border-radius: 94px;
  width: 70px;
  height: 70px;
  text-align: center;
  cursor: pointer;
  font-size: 43px;
  z-index: 5;
  box-shadow: 3px 3px 6px rgba(128,128,128,.8);
}
